<template>
  <div class="terminal-window" aria-label="Terminal Window">
      <div class="terminal-window-header">
            <svg width="40" height="10" viewBox="0 0 40 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#EF4E4E"/>
                <circle cx="20" cy="5" r="5" fill="#F7C948"/>
                <circle cx="35" cy="5" r="5" fill="#31B237"/>
            </svg>
      </div><!-- end terminal-window-header -->
      <div class="terminal-window-body">
          <span class="visible-command" :key="command.id" v-for="command in visibleCommands">
              {{command}}
          </span>
          <div class="current-command-holder">
              <span class="command-prompt">~%</span>
              <span class="current-command">{{currentCommand}}</span>
          </div><!-- end current-command-holder -->
      </div><!-- end terminal-window-body -->
  </div>
</template>

<script>
export default {
    name: 'TerminalWindow',
    data() {
        return {
            commands: [
                {
                    "id" : 1,
                    "text" : 'git checkout -b topics/AddNewTeamMember'
                },
                {
                    "id" : 2,
                    "text" : 'git add lanceanderson'
                },
                {
                    "id" : 3,
                    "text" : 'git commit -m "Add Lance Anderson to team."'
                },
                {
                    "id" : 4,
                    "text" : 'git push --set-upstream origin topics/AddNewTeamMember'
                }
            ],
            currentCommand: "",
            visibleCommands: [],
            commandNumber: 0
        }
    },
    mounted() {
        this.typeLetter(this.commands[0].text);
    },
    computed : {
        commandCount() {
            return this.commands.length;
        }
    },
    methods : {
        typeLetter: function(command) {
            let vm = this;
            const commandLength = command.length;
            let i = 0;
            let intervalFunction = setInterval(function() {
                // Every second add a new letter to currentCommand letter by letter to simulate typing into terminal window
                if(commandLength > i) {
                    const _letter = command.charAt(i);
                    vm.currentCommand += _letter;
                    i++;
                } else if (commandLength === i) {
                    // Last letter in command
                    const _letter = command.charAt(i);
                    vm.currentCommand += _letter;
                    vm.visibleCommands.push(vm.currentCommand);
                    vm.currentCommand = "";
                    vm.commandNumber++
                    if (vm.commandNumber < 4) {
                        // If we are not on the last command, then send in the next command
                        clearInterval(intervalFunction);
                        vm.typeLetter(vm.commands[vm.commandNumber].text);
                    } else {
                        // Done with all commands, kill the interval
                        clearInterval(intervalFunction);
                    }
                    
                    
                }
            }, 200)
        },
        clearTerminal: function() {
            this.visibleCommands = [];
        }
    }
}
</script>

<style lang="scss" scoped>

.terminal-window {
    background-color: $neutral-1-700;
    color: $neutral-1-050;
    border-radius: $border-radius;
    margin-top: 32px;
    margin-bottom: 32px;
    padding-bottom: 3px;
    height: 197px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: $depth-2;
    max-width: 600px;

    .terminal-window-header {
        background-color: $neutral-1-600;
        padding-left: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    .terminal-window-body {
        margin-left: 30px;
        margin-top: 32px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;;
        justify-content: flex-end;

        .visible-command {
            display: block;
            margin-bottom: 3px;
            margin-left: 32px;
        }

        .current-command-holder {
            
            .command-prompt {
                margin-right: 6px;
                font-weight: 600;
            }

            .current-command {
                position: relative;
            }

            .current-command::after {
                content:'';
                position: absolute;
                width: 8px;
                height: 20px;
                top: 1px;
                bottom: 0px;
                right: -10px;
                background-color: $neutral-1-050;
                animation: blink 1s linear infinite;

            }
        }
    }   
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

// Media Queries for small sizes
@media screen and (max-width: 1280px) {
    // Decrease font size in terminal window at very narrow sizes so it fits
    .terminal-window {
        font-size: .85rem;
    }
}

</style>