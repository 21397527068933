<template>
  <div class="home">
    <div class="hero">
      <div class="hero-content">
        <h1>hello.</h1>
        <p>My name is Lance Anderson and I am a fullstack web developer and designer who has a passion for crafting rich, beautiful, and effective web applications.</p>
        <p>I have more than fifteen years of experience in the web design and development space, from requirements gathering and refinement, project management and engineering leadership, to design and fullstack development. I enjoy studying how users interact with systems, and designing and developing innovative solutions to enhance their experiences.</p>
        <TerminalWindow class="hidden-sm" />
      </div><!-- end hero-content -->
    </div><!-- end hero -->
    <div id="headshot">
      <img src="@/assets/img/headshot.png" alt="Photo of Lance Anderson" />
    </div><!-- end #headshot -->
    <div class="main-content">
      <div class="links-bar">
        <a href="/resume">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-invoice" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3AE7E1" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
            <line x1="9" y1="7" x2="10" y2="7" />
            <line x1="9" y1="13" x2="15" y2="13" />
            <line x1="13" y1="17" x2="15" y2="17" />
          </svg>
          <span>Resume</span>
        </a>
        <a href="https://www.linkedin.com/in/lance-anderson-3780b7a/" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin" width="16" height="16" viewBox="0 0 24 24" stroke-width="1.5" stroke="#3AE7E1" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <rect x="4" y="4" width="16" height="16" rx="2" />
            <line x1="8" y1="11" x2="8" y2="16" />
            <line x1="8" y1="8" x2="8" y2="8.01" />
            <line x1="12" y1="16" x2="12" y2="11" />
            <path d="M16 16v-3a2 2 0 0 0 -4 0" />
          </svg>
          <span>LinkedIn</span>
        </a>
      </div><!-- end links-bar -->
      <ol class="connected-items">
        <li>
          <h3>Skills</h3>
          <p>Frontend Development, UX/UI Design, Backend Microservice Development, Leadership, Strategic Thinking, Project Management</p>
        </li>
        <li>
          <h3>Tools</h3>
          <p>HTML, CSS, SASS, JavaScript, VueJS, Node.js, Express, PostgreSQL, Amazon Web Services (AWS), Azure Cloud Services, Git, Figma, Illustrator, Photoshop</p>
        </li>
        <li>
          <h3>Current Status</h3>
          <span class="title">Lead Software Engineer</span>
          <span class="subtitle">BluePath Labs</span>
        </li>
      </ol>
    </div><!-- end main-content -->
  </div><!-- end home -->
</template>

<script>
// @ is an alias to /src
import TerminalWindow from "@/components/TerminalWindow.vue";

export default {
  name: "Home",
  components: {
    TerminalWindow
  }
};
</script>

<style lang="scss" scoped>

.hidden-sm {
  display: none;
}

.home {
  display: flex;
  flex-direction: column;
}

.hero {
  background-color: $primary-2-400;
  color: $neutral-1-700;
  padding: 30px;
  padding-bottom: 90px;
  max-width: 1000px;


  h1 {
    font-weight: 800;
    color: $primary-1-500;
    margin-bottom: 20px;
    font-size: 3.5rem;
    font-family: 'Roboto', sans-serif;
  }
}

p {
  margin-bottom: 12px;
}

#headshot {
  margin-top: -80px;
  
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
}

.title, .subtitle {
  display: block;
}

.title {
  font-weight: 600;
}

.main-content {
  padding: 30px;
  max-width: 800px;
}

.links-bar {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  a {
    display: flex;
    align-items: center;
    margin-right: 12px;

    svg {
      margin-right: 6px;
    }
  }
}

ol.connected-items {
  list-style-type: none;
  margin: 0;
  padding-bottom: 1em;

  li {
    position: relative;
    margin: 0;
    padding-bottom: 1em;
    padding-left: 20px;
  }

  // Create line on left
  li:before {
    content:'';
    position: absolute;
    width: 1px;
    top: 0px;
    bottom: 0px;
    left: 5px;
    background-color: $primary-1-200;
    
  }

  // Create bullet -- fill='%2398AEEB' %23 is the code for '#' so this is really just #98AEEB for the fill color
  li::after {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%2398AEEB' cx='16' cy='16' r='13'%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    left: 0;
    top: 6px;
    width: 12px;
    height: 12px;
  }

  // Bring top of line down on first li so it doesn't go too high
  li:first-child:before { top: 15px;  }

  // Bring bottom of line down on last li so it doesn't go too low
  li:last-child:before { height: 9px; }
}

// Media query for terminal window
@media screen and (min-width: 1279px) {
  .hidden-sm {
    display: block;
  }
}

// Media Queries for larger sizes
@media screen and (min-width: 1026px) {
  
  .home {
    flex-direction: row; // Flip direciton of content
  }

  .hero {
    height: 100vh;
    display: flex;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;

    .hero-content {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 80px;
    }
  }

  #headshot {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -80px;
  }

  .main-content {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 100px;
    padding-right: 80px;
    margin-left: -80px;
  }

  .links-bar {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 24px;

    a {
      margin-right: 24px;
      display: inline-flex;
    }
  }
}

</style>
